<template>
<div>
    <CRow id="top_addscreen">
        <div class="d-flex  justify-content-between  align-items-center" style="width: 100%;">
            <div>
                <h4 class="mb-0">{{ $t('mes_ecrans') }}</h4>
            </div>
            <div>
                <button class="btn btn-primary" @click="add" id="btn_new_screen">
                    <span class="material-symbols-outlined"> add_circle </span> <span>{{ $t("add_screen") }}</span>
                </button>
            </div>
        </div> 
        
    </CRow>
    <hr>
    <div v-if="this.$store.state.user.abos[0].pivot.moyen_paiement_a_jour == 1">
        <CRow > 
            
            
            <ScreenCard v-for="s in screens" :screen="s" :key="s.id"></ScreenCard> 
            <!--CCol sm="6" md="4" xl="2" class="mb-3">
                <CCard>
                    
                    <CCardBody class="body-block">
                        
                        <div class="text-center">
                            <div>
                                
                            </div>
                        <div><i class=" material-icons" style="font-size: 70px">add_circle</i></div>
                        <button class="btn btn-primary" @click="add">{{ $t("add_screen") }}</button>
                         </div>
                        
                    </CCardBody>
                    
                </CCard>
            </CCol-->
        </CRow>

        <div v-for="(c, index) in categories" :key="c.id" class="mt-2">
            <div class="cat_name_line">
                <h4>{{ c.name }}</h4> 
                <span @click.once="deleteCat(c.id)"><i class="material-icons">delete</i></span>
            </div>
            <div class="d-flex align-items-center">
                <div @click="toogleCat(index)">
                    <span v-if="c.show" class="fold material-symbols-outlined">expand_more</span> 
                    <span v-else class="fold material-symbols-outlined">expand_less</span> 
                </div>
                <hr />
            </div>
            <div class="row" v-show="c.show">
                <ScreenCard v-for="s in c.screens" :screen="s" :key="s.id"></ScreenCard>
            </div>
        </div>
    </div>
    <div v-else>
        <Crow>
            <CAlert color="danger"><router-link to="/dashboard/setting">{{ $t("merci_mettre_a_jour") }}</router-link></CAlert>
        </Crow>
    </div>
    
</div>
</template>

<script>
import ScreenCard from '@/components/ScreenCard.vue'




export default {
  name: "Dashboard",
  components:{
      ScreenCard,
     
  },
  data(){
      return{
          screens: null,
          error: 0,
          errorMsg: "",
          opened: false,
          visible: false,
          catName: "",
          categories: null,
          loaded: false
      }
  },
  created(){
      this.loadDashBoard()

  },
  methods: {
    toogleCat(index){
        this.categories[index].show = !this.categories[index].show
        console.log(this.categories)
        this.$forceUpdate();
    },
    loadDashBoard(){
        let self = this;
      window.axios.get('/api/screens/category').then((rep) => {
          self.screens = rep.data.screens
          
      });

      window.axios.get('/api/category/allcats').then((rep) => {
          self.categories = rep.data.categories
          self.categories.forEach((node) => node.show = true);
      });
    },
        deleteCat(id){
            let self = this;
            window.axios.delete('/api/categories/'+id).then( ()=> {
                self.loadDashBoard()
            });
        },
        createCat(){
           console.log(this.catName)
           
           window.axios.post('/api/categories', {name: this.catName}).then(() => {
            this.opened = this.visible = false
            this.catName = ""
           });
        },
      async add(){
          /*this.screens.push({id: 333, name: "test", bandeau: "dddddd"})
          console.log('ADD')
          console.log(this.screens)*/
          try {
                let rep = await window.axios.post("/api/screen");
                this.$router.push({ path: '/screen/'+rep.data })
            } catch (err) {
                console.log(err.response.data);
                
                if(err.response.data.message == "not_allowed")
                    this.errorMsg = this.$t("max_screen")
                else
                    this.errorMsg = this.$t("network_error")

                
                this.$notify({
                    group: "foo",
                    title: "BizScreen",
                    text: this.errorMsg,
                    type: "error",
                });
                


            }

      }
  }
};
</script>

<style scoped>
h4{
    margin-bottom: 0;
}
#btn_new_screen{
    display: flex;
    gap: 5px;
    align-items: center;
}

#top_addscreen{
   
    padding-left: 16px;
    margin-bottom: 20px;
    align-items: center;
}
.warning_msg{
    padding-left: 15px;
    color:red;
    margin-bottom: 0;
}

:focus {
  outline: none !important;
  box-shadow: none !important;
}

.body-block{
      display: flex;
    flex-direction: column;
    justify-content: center;
}

.card{
   height: 100%;
}

.alert a{
    color: #772b35;
    text-decoration: underline;
}

.rowCat > div{
    margin-left: -15px;
}

.cat_name_line{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cat_name_line i:hover{
    cursor: pointer;
}

.fold:hover{
    cursor: pointer;
}

hr{
    flex-grow: 1;
}
</style>
