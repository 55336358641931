<template>
    <CCol sm="6" md="4" xl="2" class="mb-3">
  <CCard>
      
      <CCardBody class="body-block">
        <!--p>Texte bandeau : {{ screen.bandeau }}</p>
        <p v-if="screen.online"><span class="text-success">online</span></p>
        <p v-else><span class="text-danger">offline</span></p-->
        <p><span class="or">{{ $t("screen_name") }} :</span><br /><span class="big-text">{{ screen.name }}</span></p>
        <!--p><span class="or">{{ $t("screen_id") }} :</span><br /><span class="big-text">{{ screen.id_rasb }}</span></p-->
        <div class="text-center line-btn" >
          <router-link :to="{ name: 'Screen', params: { id: screen.id } }">
          <button class="btn btn-primary">
                 <i class="material-icons" >settings</i> {{ $t("configure") }}
           </button>
          </router-link>
        </div>
         
       </CCardBody>
      
  </CCard>
    </CCol>
</template>

<script>
export default {
  name: "ScreenCard",
  props: {
    screen: {
      type: Object,
    },
  },
};
</script>

<style scoped>
.card{
   height: 100%;
}

.line-btn{
  display: flex;
    justify-content: center;
}

.big-text{
  font-size: 21px;
}

a{
  text-decoration: none !important;
}

a button, a:hover button{
  color:white;
  text-decoration: none !important;
}

.body-block{
      display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.btn{
  display: flex;
  align-items: center;
}

.btn i{
  width:20px;
  height: 20px;
  font-size: 20px;
  margin-right: 5px;
}


</style>
